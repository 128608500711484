<template>
  <div class="lead-intake-top">
    <div class="lead-intake-left">
      <hb-breadcrumb @click="$emit('openorClosetheSendQuote')" class="ma-4 pa-0">
        Back to Lead
      </hb-breadcrumb>
      <div class="ma-0 pa-4">
        <mini-profile-view v-if="contact?.id" :contact-id="contact.id" :use-contact="false" :contact-data="contact" />
      </div>
      
      <v-divider class="ml-4"></v-divider>
      <div style="flex: 1; overflow: auto; background: #F9FAFB;">
      <div class="ml-3 mr-3 mt-2">
        <div class="mb-2">
          <span class="hb-font-header-2-medium">Send a Quote</span>
        </div>
        <hb-notification :v-model="showSendQuoteNotification" type="guidance" :icon-off="true" :notDismissable="true"
          v-if="!propertyUnits?.length > 0">
          Select the spaces you would like to add to the quote.
          <template v-slot:actions>
            <div style="margin-top: 7px;">
              <HbIcon mdi-code="mdi-arrow-right-circle" color="#3D8FFF" />
            </div>
          </template>
        </hb-notification>
      </div>
      <div>
        <send-quote-summary :selectedPropertyUnits="propertyUnits" :isSendQuoteLoading="isSendQuoteLoading"
          v-if="propertyUnits?.length > 0"></send-quote-summary>
      </div>
      </div>
    </div>
    <div class="lead-intake-right">
      <v-container class="pl-4 pr-0">
        <v-row no-gutters>
          <v-col cols="12 pt-2">
            <hb-header :divider="false">
              <HbPageHeader title="Select Spaces" description="View and select spaces from all properties." />
            </hb-header>
          </v-col>
          <v-col cols="12 mt-n4">
            <HbPropertySelector :items="properties" item-text="name" item-value="id" label="Select A Property"
              v-model="property_id" @change="updateProperty" :key="property_id"
              :menu-props="{ contentClass: 'facility-selector-dense-menu-fix' }">
            </HbPropertySelector>
          </v-col>
          <v-col cols="12" class="pl-0 pr-3">
            <filter-dropdown-menu :filter_data="filter_data" :search="search" :reset="false" :tab="active_tab" :isSendQuote="true"
              @resetFilters="resetFilters" @setValues="setValues" @spaceStatusChange="changeSpaceStatus">
            </filter-dropdown-menu>
          </v-col>
          <v-col cols class="pa-0 mr-0">
            <send-quote-unit-selector :search="search" :unit_id="moveInUnitId" :key="active_tab" :property="property"
              @receiveSpaceList="setSendQuoteSelectedSpaces"></send-quote-unit-selector>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div  class="custom-loader" v-if="isSendQuoteLoading">
      <v-progress-circular :v-show="isSendQuoteLoading" indeterminate color="primary" > 
      </v-progress-circular>
    </div>
  </div>
</template>

<script type="text/babel">
import api from '../../../assets/api.js';
import MiniProfileView from '../LeadIntake/MiniProfileView.vue'
import FilterDropdownMenu from '../../FilterDropdownMenu.vue'
import SendQuoteUnitSelector from './SendQuoteUnitSelector.vue';
import SendQuoteSummary from './SendQuoteSummary.vue';
import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
  name: 'SendQuote',
  props: ['moveInUnitId', 'moveInUnit'],
  data: () => ({
    filter_data: [
      {
        open: false,
        label: 'Type',
        items: [],
        data: []
      },
      {
        open: false,
        label: 'Size',
        items: [],
        data: []
      },
      {
        open: false,
        label: 'Price',
        items: ['< $50', '$50 - $100', '$100 - $150', '$150 - $300', '> $300'],
        data: ''
      },
      {
        open: false,
        label: 'Floor',
        items: [],
        data: []
      },
      {
        open: false,
        label: 'Amenities',
        items: [],
        data: []
      },
    ],
    active_tab: 'spaces',
    property_id: '',
    search: {
      search: '',
      type: [],
      price: [],
      size: [],
      amenities: [],
      floor: [],
      status: ['Available'],
    },
    selectedSpaces: [],
    showSendQuoteNotification: true,
    defaulTaxRates: [
      { id: null, name: 'Merchandise', type: 'merchandise', tax_profile_id: null, tax_profile: '', gl_account_code: '', gl_account_name: '', tax_rate: '' },
      { id: null, name: 'Fees', type: 'fee', tax_profile_id: null, tax_profile: '', gl_account_code: '', gl_account_name: '', tax_rate: '' },
      { id: null, name: 'Insurance', type: 'insurance', tax_profile_id: null, tax_profile: '', gl_account_code: '', gl_account_name: '', tax_rate: '' },
      { id: null, name: 'Auctions', type: 'auction', tax_profile_id: null, tax_profile: '', gl_account_code: '', gl_account_name: '', tax_rate: '' },
      { id: null, name: 'Deposit', type: 'deposit', tax_profile_id: null, tax_profile: '', gl_account_code: '', gl_account_name: '', tax_rate: '' }
    ],
    isSendQuoteLoading: false
  }),
  components: {
    MiniProfileView,
    FilterDropdownMenu,
    SendQuoteUnitSelector,
    SendQuoteSummary
  },
  methods: {
    async updateProperty(propertyId) {
      await this.$store.dispatch('sendQuoteStore/getPropertyInfo', this.properties.find(p => p.id === propertyId));
      // await this.$store.dispatch('sendQuoteStore/getPropertyUnitsInfo', []);
    },
    async onApplyPropertyMap(search) {
      let { unit_size, unit_status, unit_type } = search;
      this.spacesWithCoordinates.forEach((space, i) => {
        // Filtering: A space will be a match (and not filtered out) if
        // 1. A filter category the space is being compared to has nothing to filter against
        // 2. The space attribute (size,status,type) matches one of the filter options for that filter category
        // If a space matches `true` for all filter categories then it is a match and not deemed 'filtered-out'.
        let matchesSizeFilter =
          !unit_size.length || unit_size.indexOf(space.label) > -1,
          matchesStatusFilter =
            !unit_status.length || unit_status.indexOf(space.state) > -1,
          matchesTypeFilter =
            !unit_type.length || unit_type.indexOf(space.type) > -1;
        this.spacesWithCoordinates[i].filterMatch =
          matchesSizeFilter && matchesStatusFilter && matchesTypeFilter;
        return;
      });
    },
    resetFilters() {
      for (let i = 0; i < this.filter_data.length; i++) {
        this.filter_data[i].data = [];
      }
      this.search.search = '';
      this.search.price = [];
      this.search.size = [];
      this.search.type = [];
      this.search.amenities = [];
      this.search.floor = [];
      this.search.status = this.search.status ?? [];
    },
    changeSpaceStatus(value) {
      this.search.status = value ? [value] : [];
      let mapFilters = {
        location: 'leadFlow',
        unit_size: this.search.size,
        unit_type: this.search.type,
        unit_status: this.search.status,
      }
      this.onApplyPropertyMap(mapFilters)
    },
    setValues(mi) {
      this.search[mi.label.toLowerCase()] = mi.data;
      let mapFilters = {
        location: 'leadFlow',
        unit_size: this.search.size,
        unit_type: this.search.type,
        unit_status: this.search.status,
      }
      this.onApplyPropertyMap(mapFilters)
      mi.open = false;
    },
    setFilterValues(spaceFilter) {
      this.search.size = spaceFilter.size ? [spaceFilter.size] : [];
      this.search.price = spaceFilter.price ? [spaceFilter.price] : [];
      this.search.floor = spaceFilter.floor ? [spaceFilter.floor] : [];
      this.search.type = spaceFilter.type ? [spaceFilter.type] : [];
      this.search.amenities = spaceFilter.amenities ? spaceFilter.amenities : [];
      this.search.status = this.search.status ?? ['Available']
    },
    sendQuoteClick() {
    },
    getUnitTypesFilterOptions() {
      this.filter_data[0].items = this.getUnitTypes();
    },
    async getSizes() {
      let r = await api.get(this, api.UNITS + 'options', { property_id: this.property.id });
      this.filter_data[1].items = r.options.map(o => o.width + "' x " + o.length + "'");
    },
    async getFloors() {
      let r = await api.get(this, api.UNITS + 'floors', { property_id: this.property_id });
      this.filter_data[3].items = r.floors.map(o => o.name);
    },
    async getAmenities() {
      let r = await api.get(this, api.UNITS + 'amenities' + '/all', { property_id: this.property.id });
      this.filter_data[4].items = r.amenities.map(o => o.name);
    },
    async setSendQuoteSelectedSpaces(value) {
      this.isSendQuoteLoading = true;
      let isNewTenant = (this.contact?.Leases?.length>0)?false:true;
      let propertyUnitsParse = this.propertyUnits?.length > 0 ? JSON.parse(JSON.stringify(this.propertyUnits)) : [];
      const findProperty = propertyUnitsParse.find(propData => propData?.property?.id === this.property?.id);
      try{
        if (findProperty) {
        propertyUnitsParse = await Promise.all(
          propertyUnitsParse.map(async propData => {
            let unitValueLength = true;
            if (propData?.property?.id === this.property?.id) {
              let oldUnits = propData?.units ? JSON.parse(JSON.stringify(propData.units)) : [];

              let unitValues = oldUnits; // Default to existing units
              if (value?.selected?.length > 0) {
                const currentUnitValues = await Promise.all(
                  value.selected.map(async unitValue => {
                    const existingItem = oldUnits.find(oldUnit => oldUnit.unit_id === unitValue.unit_id);
                    if (!existingItem) {
                      const r = await api.get(this, api.PROPERTIES + this.property.id + '/insurances', { unit_type_id: unitValue?.unit_type_id });
                      const activePromotion = await this.fetchActivePromotions(this.property.id, unitValue.unit_id, 'promo-plan', isNewTenant);
                      const table_rate_insurance = (r?.insurances?.length && r.insurances[0]?.insurance_type === "table_rate" )? true : false;
                      return {
                        ...unitValue,
                        table_rate_insurance:table_rate_insurance,
                        unitLevelInsurances: r?.insurances || [],
                        unitLevelPromotions: activePromotion || [],
                        selectedInsurance: '',
                        selectedMerchandise: [],
                        selectedFees: [],
                        selectedPromotions: [],
                        filteredPromotions: activePromotion || [],
                        selectedDiscount: '',
                        isSelectDiscount: false,
                        isSelectPromo: false,
                        promotionCount: 0
                      };
                    }
                    return existingItem;
                  })
                );
                // Merging old and new unit values
                const mergedUnitValues = [...oldUnits];
                currentUnitValues.forEach(currentUnit => {
                  const existing = mergedUnitValues.find(oldUnit => oldUnit.unit_id === currentUnit.unit_id);
                  if (existing) {
                    Object.assign(existing, currentUnit);
                  } else {
                    mergedUnitValues.push(currentUnit);
                  }
                });
                // Remove unchecked items if needed
                unitValues = value?.unCheckedItem
                  ? mergedUnitValues.filter(unit => unit?.unit_id !== value?.unCheckedItem.unit_id)
                  : mergedUnitValues;
              } else if (value?.unCheckedItem) {
                // Remove unchecked item if no new selections
                unitValues = oldUnits.filter(unit => unit?.unit_id !== value?.unCheckedItem.unit_id);
              }
              unitValueLength = (unitValues?.length)?true:false;
              propData.units = unitValues;
            }
            return (unitValueLength)?propData:null;
          })
        );
        propertyUnitsParse =propertyUnitsParse?.filter(item => item !== null);
        await this.$store.dispatch('sendQuoteStore/getPropertyUnitsInfo', propertyUnitsParse);
        this.isSendQuoteLoading = false;
      } else {
        // Fetch property-related data for a new property
        const [propertyTax, servicesByProperty, discountsResponse] = await Promise.all([
          this.getPropertyTaxRates(this.property.id),
          this.fetchServicesByProperty(this.property.id),
          api.get(this, api.getPropertyDiscountManagementUrl(this.property.id))
            .then(response => response?.[0]?.discounts || [])
            .catch(err => {
              console.error('Failed to fetch discounts:', err);
              return [];
            })
        ]);
        // Fetch unit values
        const unitValues = await Promise.all(
          value?.selected.map(async unitValue => {
            const r = await api.get(this, api.PROPERTIES + this.property.id + '/insurances', { unit_type_id: unitValue?.unit_type_id });
            const activePromotion = await this.fetchActivePromotions(this.property.id, unitValue.unit_id, 'promo-plan', isNewTenant);
            const table_rate_insurance = (r?.insurances?.length && r.insurances[0]?.insurance_type === "table_rate" )? true : false;
            return {
              ...unitValue,
              table_rate_insurance:table_rate_insurance,
              unitLevelInsurances: r?.insurances || [],
              unitLevelPromotions: activePromotion || [],
              selectedInsurance: '',
              selectedMerchandise: [],
              selectedFees: [],
              selectedPromotions: [],
              filteredPromotions: activePromotion || [],
              selectedDiscount: '',
              isSelectDiscount: false,
              isSelectPromo: false,
              promotionCount: 0
            };
          })
        );
        propertyUnitsParse.push({
          property: this.property,
          propertyTax,
          discounts: discountsResponse,
          servicesByProperty,
          units: unitValues,
        });
        await this.$store.dispatch('sendQuoteStore/getPropertyUnitsInfo', propertyUnitsParse);
        this.isSendQuoteLoading = false;
      }
      }catch(error){
        console.log("----------------Error", error);
        this.isSendQuoteLoading = false;
      }
    },
    async fetchActivePromotions(property_id, unit_id, channel_type, isNewTenant) {
      const queryParams = new URLSearchParams();
      if (this.contact?.id) queryParams.append('contact_id', this.contact?.id);
      if (property_id) queryParams.append('property_id', property_id);
      if (unit_id) queryParams.append('unit_id', unit_id);
      if (channel_type) queryParams.append('channel_type', channel_type);

      let promotions = [];
      try {
        const response = await api.get(this, `${api.PROMOTIONS}?${queryParams.toString()}`);
        promotions = response.promotions.filter((promo) => {
          if (promo?.enable && promo?.label === "promotion" && promo?.period?.offset === 0) {
              if (promo?.conditions?.length) {
                  if (isNewTenant) {
                      return promo?.conditions?.includes('new_tenants'); // Returns true or false
                  }
                  return true;
              }
              return true;
          }
          return false;
        });
        return promotions;
      } catch (err) {
        console.error('Failed to fetch promotions:', err);
        return promotions;
      }
    },
    async getPropertyTaxRates(property_id) {
      let propertyTaxRates = [];
      await api.get(this, api.PROPERTIES + property_id + '/tax-rates').then(r => {
        propertyTaxRates = r?.tax_rates || [];
      });

      let taxRatesData = [];

      // Filter out the rent-related items from the propertyTaxRates array
      const rentItems = propertyTaxRates.filter(item => item.unit_type_id != null).map(v => v.unit_type_id);

      // Loop through the unit types that are available and have taxes
      this.getUnitTypes('have_taxes').forEach(element => {
        // If the unit_type_id exists in the rentItems array, push the rent tax data to the final taxRatesData array
        if (rentItems.indexOf(element.unit_type_id) != -1) {
          taxRatesData.push({
            id: null,
            name: element.display_name + ' Rent', // Display name with 'Rent' suffix
            type: 'rent',
            category: element.unit_type_name,
            tax_profile_id: null,
            tax_profile: '',
            gl_account_code: '',
            gl_account_name: '',
            tax_rate: propertyTaxRates.find(rate => rate.unit_type_id === element.unit_type_id)?.tax_rate || 0, // Find the corresponding tax rate for this unit type
            unit_type_id: element.unit_type_id
          });
        }
      });

      // Now handle the default tax rates like merchandise, fee, insurance, etc.
      this.defaulTaxRates.forEach(tr => {
        const matchingRate = propertyTaxRates.find(rate => rate.type === tr.type);
        taxRatesData.push({
          ...tr,
          tax_rate: matchingRate ? matchingRate.tax_rate : 0
        });
      });

      return taxRatesData;
    },
    async fetchServicesByProperty(property_id) {
      try {
        var merchandiseProducts = [];
        var feesProducts = [];
        await api.get(this, `${api.PROPERTIES}${property_id}/products`, {
          type: 'product',
          source: 'property_products'
        }).then(merchandiseResponse => {
          merchandiseProducts = (merchandiseResponse?.products || [])
            .filter(p => p.default_type === 'product')
            .map(p => {
              const { merchandise_details } = p;
              let foundImage = null;

              if (merchandise_details?.Images?.length) {
                foundImage = merchandise_details.Images.find(item => item.is_thumbnail) || merchandise_details.Images[0];
              }

              this.$set(p, 'qty', p.qty || 1);
              this.$set(p, 'image', foundImage);
              this.$set(p, 'service_price', p.price);
              this.$set(p, 'description', p.description);
              return p;
            });
        });
        await api.get(this, `${api.PROPERTIES}${property_id}/products`, {
          type: 'late',
          category_type: 'movein'
        }).then(feesResponse => {
          feesProducts = (feesResponse?.products || [])
            .filter(p => p.default_type === 'late')
            .map(p => {
              this.$set(p, 'qty', p.qty || 1);
              this.$set(p, 'service_price', p.price);
              this.$set(p, 'start_date', moment().format('YYYY-MM-DD'));
              return p;
            });
        });

        return { merchandiseProducts, feesProducts };
      } catch (error) {
        console.error("--------Fetch Product error-------", error);
        return { merchandiseProducts: [], feesProducts: [] };
      }
    }

  },
  async created() {
    await this.getSizes();
    await this.getFloors();
    await this.getAmenities();
    await this.getUnitTypesFilterOptions();
    this.$emit('update:loading', false)
  },
  mounted() {
    this.property_id = this.property.id;
    let checkSelectedSpace = {
      selected: [
          {
            unit_number_no: this?.moveInUnit?.number,
            unit_number: this?.moveInUnit?.number,
            unit_size: this?.moveInUnit?.label,
            unit_type: this?.moveInUnit?.type,
            unit_floor: this?.moveInUnit?.floor,
            unit_amenities: (this?.moveInUnit?.Amenities?.length)?this.moveInUnit.Amenities.map(item => item.value).join(","):"",
            unit_status: this?.moveInUnit?.status,
            unit_price: this?.moveInUnit?.price,
            unit_id: this?.moveInUnit?.id,
            unit_type_id: this?.moveInUnit?.unit_type_id
          }
      ],
      unCheckedItem: null
    }
    if(this.moveInUnit){
      this.setSendQuoteSelectedSpaces(checkSelectedSpace)
    }
    
  },
  computed: {
    ...mapGetters({
      properties: 'propertiesStore/primaryRoleProperties',
      property: 'sendQuoteStore/send_quote_property',
      propertyUnits: 'sendQuoteStore/propertyUnits',
      contact: 'onBoardingStore/contact',
    })
  },
  watch: {
    "property.id"() {
      this.resetFilters();
      this.property_id = this.property.id;
      this.getSizes();
      this.getFloors();
      this.getAmenities();
      this.getUnitTypesFilterOptions();
      this.search.status = ['Available']
    },
    async selectedSpaces(val) {
      this.showSendQuoteNotification = (val?.length > 0) ? false : true;
    },
  },
  destroyed() {
    this.$store.commit('sendQuoteStore/setPropertyUnits', []);
    this.$store.commit('sendQuoteStore/setProperty', { id: '' });
  },
}
</script>


<style scoped>
/* Your CSS styles specific to LeadIntake component */

.lead-intake-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  flex-grow: 1;
  overflow: hidden;
}

.lead-intake-left {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  flex: 5;
  max-width: 730px;
  min-width: 390px;
  overflow: hidden;
  height: 100%;
  box-shadow: 8px 0px 18px rgba(71, 84, 116, 0.2) !important;
  z-index: 202 !important;
}

.lead-intake-right {
  flex: 4;
  max-width: 570px;
  min-width: 390px;
  display: flex;
  overflow: hidden;
}

.lead-intake-right>>>.v-slide-group__wrapper {
  border-bottom: 1px solid #E1E6EA
}


.lead-intake-right>>>.v-window {
  height: calc(100% - 27px) !important;
}
.custom-loader {
  position: fixed;
  top: 80px;
  left: 10px;
  width: 55.2%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7); /* Optional: semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it appears above other elements */
}
</style>
