<template>
    <div class="pr-3" :style="{ height: reportHeight + 'px' }" v-resize="setHeight">
      <hb-report report_type="move_in" :key="'send_quote - ' + report_key" :report_filters="filters" row_click
        show_filters :actions_panel="['bulk_edit']" :disable_header_filters="true" :column-conf="columnConf"
        :conditions="condition" :isMultipleEdit="isMultiSelection" :left_slot="false" :send_quote_property_id="this.property?.id"
        :sendQuotePropertyUnits="this.propertyUnits" @update-selected-space-numbers="handleSelectedSpaceNumbers"
        :ref="'hbReportsRef'" active-tab="list"></hb-report>
    </div>
</template>


<script type="text/babel">
import api from '../../../assets/api.js';
import HbReport from '../../assets/HummingbirdReportViewer.vue';
import { EventBus } from '../../../EventBus.js'
import { mapGetters } from 'vuex';
import MultiLineRenderer from '../../../components/BI/MultiLineRenderer.vue'
import UnitIconField from '../../revenue_management/utils/UnitIconField.vue';
export default {
  name: 'SendQuoteUnitSelector',
  components: {
    HbReport,
    MultiLineRenderer
  },
  data: () => ({
    options: {},
    report_key: 0,
    property_id: '',
    panel: null,
    isMultiSelection: true,
    reportHeight: 0,
    table_data: [],
    selectedSpaceNumbersFromChild: {selected:[], unCheckedItem:null},
    filters: {
      search: {
        search: '',
        property_id: [],
        unit_status: ['Available'],
        unit_size: [],
        unit_amenities: [],
        unit_floor: [],
        unit_price: [],
        unit_type: [],
      },
      columns: [
        {
          "label": "Space #",
          "key": "unit_number",
          "sortable": true,
          "width": "90",
          "column_type": "string",
          "agg_method": "Count",
          "format": "",
          "fixed": false,
          "group": "unit",
          "hide": true
        },
        {
          "label": "Space #",
          "key": "unit_size",
          "sortable": true,
          "width": "90",
          "column_type": "string",
          "agg_method": "Count",
          "input": "multi-select",
          "format": "",
          "fixed": false,
          "group": "unit"
        },
        {
          "label": "Space Type",
          "key": "unit_type",
          "input": "multi-select",
          "width": "120",
          "sortable": true,
          "format": "",
          "fixed": false,
          "group": "unit",
          "hide": true
        },
        {
          "label": "Space Type",
          "key": "unit_floor",
          "input": "multi-select",
          "width": "120",
          "sortable": true,
          "format": "",
          "fixed": false,
          "group": "unit",
          "hide": true
        },
        {
          "label": "Group Amenities",
          "key": "unit_amenities",
          "sortable": true,
          "width": 95,
          "column_type": "concat",
          "agg_method": "Count",
          "format": "",
          "fixed": false,
          "input": "multi-select",
          "group": "unit",
        },
        {
          "label": "Promotion",
          "key": "unit_in_store_promotion",
          "sortable": true,
          "width": "120",
          "column_type": "string",
          "format": "",
          "fixed": false,
          "group": "unit",
        },
        {
          "label": "Space Status",
          "key": "unit_status",
          "sortable": true,
          "width": 168,
          "column_type": "status",
          "agg_method": "Count",
          "format": "",
          "fixed": false,
          "input": "multi-select",
          "options": [
            "Available",
            "Leased",
            "Pending",
            "On Hold",
            "Offline",
            "Reserved",
            "Overlocked",
            "Remove Overlock",
            "To Overlock",
            "Future Leased",
            "Deactivated",
          ],
          "search": [],
          "group": "space",
        },
        {
          "label": "Price",
          "key": "unit_price",
          "sortable": true,
          "width": "84",
          "column_type": "money",
          "agg_method": "Count",
          "format": "",
          "fixed": true,
          "group": "unit",
          "input": "comparison"
        },
      ],
      "sort": {
        "field": "unit_number",
        "sort": "ASC"
      },
      "pivot_mode": {
        "enabled": false,
        "column": {},
        "metric": {
          "field": {},
          "method": ""
        },
        "row": {}
      },
      "groups": [],
      "limit": 50,
      "page": 1,
      "offset": 0
    }
  }),
  async created() {
    this.property_id = this.property.id;
    if (this.property.id) {
      this.filters.search.property_id = [this.property_id];
      this.report_key++;
    }

    if (this.search && this.search.type && this.search.type.length) {
      this.filters.search.unit_type_id = JSON.parse(JSON.stringify(this.search.type));
    }
    this.setHeight();
    EventBus.$on('remove_selected_unit', (propId, unitId) => this.removeSelectedPropertyAndUnit(propId, unitId, 'unit'));
    EventBus.$on('remove_selected_property', (propId) => this.removeSelectedPropertyAndUnit(propId, null, 'property'));

  },
  computed: {
    ...mapGetters({
      properties: 'propertiesStore/filtered',
      property: 'sendQuoteStore/send_quote_property',
      propertyUnits: 'sendQuoteStore/propertyUnits',
    }),
    columnConf() {
      return {
        unit_price: {
          pinned: "right",
          lockPosition: "right",
          suppressNavigable: true
        },
        unit_size: {
          cellRenderer: "MultiLineRenderer",
          cellRendererParams: {
            fields: [
              {
                column: "unit_number",
                tooltip: true,
                component: {
                  component: UnitIconField,
                  props: {
                    position: "left",
                    applyStroke: false,
                    small: true,
                    width: 16,
                    height: 16,
                    color: "black",
                    marginBottom: "0px",
                    verticalAlign: "middle",
                  },
                },
              },
              {
                column: "unit_size",
                color: "#8E8E8F",
                tooltip: true,
              },
            ],
          },
        },
      }
    }
  },
  filters: {
    promotions_display(data) {
      let promotions = data.split(',');
      if (!promotions.length) return ''
      return promotions[0] + ' + ' + (promotions.length - 1) + ' more';
    },
  },
  props: ['search', 'condition', 'allSpaces', 'unit_id'],
  methods: {
    async handleSelectedSpaceNumbers(value) {
      this.selectedSpaceNumbersFromChild = await value;
      this.$emit('receiveSpaceList', this.selectedSpaceNumbersFromChild);
    },
    setHeight() {
      let header = 72;
      let stepper = 100;
      let tabs = 48;
      let footer = 72;
      let heading = 122;
      let padding = 20;
      this.reportHeight = window.innerHeight - header - stepper - tabs - heading - footer - padding;
    },
    updateColumnSearch(columns, searchCriteria) {
      return columns.map(column => {
        if (searchCriteria.hasOwnProperty(column.key)) {
          return {
            ...column,
            search: searchCriteria[column.key]
          };
        }
        return column;
      });
    },
    async removeSelectedPropertyAndUnit(propId, unitId, removeType) {
      // Deep clone propertyUnits
      let propertyUnitsParse = JSON.parse(JSON.stringify(this.propertyUnits));
      // Process propertyUnits
      propertyUnitsParse = propertyUnitsParse
        .map((propData) => {
          if (propData?.property?.id === propId) {
            if (removeType === 'property') {
              // Remove the entire property
              return null;
            }

            // Remove specific unit
            propData.units = propData.units.filter(
              (unitValue) => unitValue.unit_id !== unitId
            );

            // If no units remain, remove the property
            if (propData.units.length === 0) {
              return null;
            }
          }
          return propData;
        })
        .filter((propItem) => propItem !== null); // Remove null items
      if (propId == this.property?.id) {
        if (removeType === 'property') {
          this.$refs.hbReportsRef.gridApi.forEachNode((node) => {
            node.setSelected(false);
          });
        } else {
          this.$refs.hbReportsRef.gridApi.forEachNode((node) => {
            if (node?.data?.unit_id == unitId) {
              node.setSelected(false);
            }
          });
        }
      }
      // Dispatch updated property units to the store
      await this.$store.dispatch(
        "sendQuoteStore/getPropertyUnitsInfo",
        propertyUnitsParse
      );
    }

  },
  watch: {
    "property.id"() {
      this.property_id = this.property.id;
      this.filters.search.property_id = [this.property.id];
      if (this.property.id) {
        this.report_key++;

      }
    },
    search: {
      async handler(value) {
        this.filters.search.search = value.search || ''
        this.filters.search.unit_floor = value.floor;
        this.filters.search.unit_size = value.size;
        this.filters.search.unit_type_id = value.type;
        this.filters.search.unit_status = value.status
        if (value.price && value.price.length) {
          this.filters.search.unit_price = []
          value.price.forEach((price) => {
            switch (price) {
              case '< $50':
                this.filters.search.unit_price.push({ max: "", operator: "<", value: "50" })
                break;
              case '$50 - $100':
                this.filters.search.unit_price.push({ max: "100", operator: "range", value: "50" })
                break;

              case '$100 - $150':
                this.filters.search.unit_price.push({ max: "150", operator: "range", value: "100" })
                break;

              case '$150 - $300':
                this.filters.search.unit_price.push({ max: "300", operator: "range", value: "150" })
                break;

              case '> $300':
                this.filters.search.unit_price.push({ max: "", operator: ">", value: "300" })
                break;

            }
          })

        } else {
          this.filters.search.unit_price = value.price
        }

        this.filters.search.unit_amenities = value.amenities;
        let updatedColumns = this.updateColumnSearch(this.filters.columns, this.filters.search)
        this.filters.columns = updatedColumns;
        this.report_key++;
      },
      deep: true
    },
  },
  mounted() {
    setTimeout((function () {
      this.$refs.hbReportsRef.gridApi.forEachNode((node) => {
        if (node?.data?.unit_id == this.unit_id) {
          node.setSelected(true);
        }
      });
    }).bind(this), 3000);
  },
  destroyed() {
    EventBus.$off('remove_selected_unit');
    EventBus.$off('remove_selected_property');
  },
}

</script>

<style scoped>
.unit-selector-dt {
  border: 1px solid rgba(11, 18, 29, .1)
}
</style>
